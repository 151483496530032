import util from '@dbiqe/glu-core/src/util';
import locale from '@glu/locale';
import services from 'services';
import EnumCollection from 'common/dynamicPages/collections/enum';
import userInfo from 'etc/userInfo';
import constants from 'app/administration/views/userMaintenance2/accounts/constants';
import { maskValue } from './maskingUtil';

export default {
    /**
     * @param inquiryId - inquiry id for the column
     * @return Promise
     * Fetches existing Status Codes from the service to use on
     */
    fetchStatusCodes(inquiryId) {
        return new Promise(((resolve, reject) => {
            new EnumCollection([], {
                serviceUrl: services.generateUrl('inquiry/getRowsData'), // for typeaheads & enums
                inquiryId,
            }).fetch({
                success: resolve,
                error: reject,
            });
        }));
    },
    /**
     * @param {array} filters
     * @param {array} refCollection - filter references
     * @param {string} functionCode
     * @return {array} translated filters
     * Hard translates filter value codes on the UI side that aren't originally from DGB
     * Filters translated by a field code basis
     */
    hardTranslateFilters(filters, refCollection, functionCode) {
        // early return if we have no filters
        if (filters.length < 1 && refCollection == null) {
            return filters;
        }
        return util.map(filters, (item) => {
            switch (item.fieldCode) {
            /**
             * TODO: Temporary hack: Items with fieldCode below will return
             * null if backend returns SQL or if the UI shouldn't
             * display
             */
            case 'TESTFILEIMPORT':
            case 'SQLWHERE':
                return null;
            case 'ENTERED_BY':
                return this.translateEnteredBy(item);
            case 'USER_ID':
                return this.translateEnteredBy(item);
            case 'ENTRYMETHOD':
                return this.translateEntryMethod(item);
            case 'SCHEDULED':
                return this.translateScheduled(item);
            case 'STATUS':
                return this.translateStatus(item, refCollection, functionCode);
            case 'TRAN_DATE':
                return this.translateTransactionDate(item);
            case 'PRODUCT':
                return this.translateProduct(item);
            case 'LASTTOUCHEDBYAPPLICATION':
                return this.translateLastTouchedBy(item);
            case 'MESSAGE_STATE':
                return this.translateMessageState(item, refCollection);
            case 'BAI_CODE':
                return this.translateBAICode(item);
            case 'TRANSFERSET':
            case 'ISRECURRING':
                return this.translateTransferSet(item);
            case 'STATUS_DESCRIPTION':
            case 'EXPIRATION_CODE':
            case 'EXPIRATION_INFORMATION':
                return this.translateStatusDescription(item);
            case 'TYPE_DESCRIPTION':
                return this.translateTypeDescription(item);
            case 'REQUEST_STATUS':
            case 'PAYMENT_STATUS':
                return this.translateRequestPaymentStatus(item);
            case 'REQUESTED_EXECUTION_DATE':
                return this.translateRequestDate(item);
            case 'BAI_GROUP_CODE':
                return this.translateBAIGroupCode(item);
            case 'TYPE':
                return this.translateType(item);
            case 'LAST_ACTION_TIME':
                return this.translateLastActionTime(item);
            case 'CURRENCYCODE':
            case 'CURRENCY_CODE':
                return this.translateCurrencyCode(item, functionCode);
            case 'ACCOUNTFILTER':
                return this.translateAccountFilter(item);
            case 'TOKENEXPDATE':
                return this.translateTokenExpDate(item);
            case 'USERGROUP':
                return this.translateUserGroup(item);
            case 'ENTITLE':
                return null;
            case 'AREPAYMENTSEXEMPT':
            case 'APPLYTAXESFORPAYMENT':
            case 'ALLOWFORWIREINTERNATIONAL':
            case 'SHOWDISCLOSURE':
            case 'MOBILE':
            case 'PRENOTEFLAG':
            case 'HOLDFLAG':
                return this.translateYesNo(item);
            case 'ACTIVE':
                return this.translateActive(item);
            case 'ACCOUNTDISPLAY':
            case 'ACCOUNT_NUMBER_DISP':
            case 'BANK_ID':
                return this.translateAccountDisplay(item);
            case 'CUST_REF':
            case 'DESCRIPTION':
                return this.translateImageSearchFields(item);
            case 'IMPORTID':
                return this.translateImportId(item);
            case 'CONTENTTYPE':
                return this.translateContentType(item);
            case 'ACCTPOSPAYTYPE':
                return this.translatePositivePayType(item);
            case 'DR_CR':
                return this.translateDebitCredit(item);
            case 'ACTIONED':
                return null;
            default:
                return item;
            }
        }).filter(a => a !== null);
    },

    /**
     * Helper method to modify list of filter values
     * @param {array} primaryValues - list of filter values to be modified
     * @param fn - custom map function
     * @returns {array} - modified values
     */
    mapPrimaryValues(primaryValues, fn) {
        return util.chain(primaryValues)
            .map(fn)
            .compact()
            .value();
    },

    /**
     * @param {Object} filter
     */
    translateActive(filter) {
        const filterItem = filter;
        filterItem.fieldName = locale.get('ACH.Active');
        filterItem.primaryValues = [filterItem.labelValue];
        return filterItem;
    },

    /**
     * @param {Object} filter
     */
    translateImportId(filter) {
        const filterItem = filter;
        filterItem.primaryValues = filterItem.primaryValues.map((val) => {
            switch (val) {
            case 'ISNULL':
                return locale.get('CM.Manual');
            case 'ISNOTNULL':
                return locale.get('CM.Import');
            default:
                return val;
            }
        });
        return filterItem;
    },

    /**
     * Each filter in User Maint for each tab as an ACCOUNTDISPLAY fieldcode but different
     * filter values, this translates to the appropriate filter value based on active tab.
     * @param {Object} i filter object
     * @return {object}
     */
    translateAccountDisplay(i) {
        const item = i;
        if (item.fieldCode === 'ACCOUNTDISPLAY') {
            const entityId = document.querySelector('.checkGrid').dataset.gridEntity;
            item.fieldName = locale.get(constants.REQUEST_ENTITY_LOCALE_KEYS[entityId]);
        } else {
            const localeKeys = {
                ACCOUNT_NUMBER_DISP: 'CM.Account',
                BANK_ID: 'CM.ABA',
            };
            item.fieldName = locale.get(localeKeys[i.fieldCode]);
        }
        item.type = 'typeahead';
        return item;
    },

    /**
     * @param {Object} i filter
     * @return {Object}
     */
    translateImageSearchFields(i) {
        const item = i;
        switch (item.fieldCode) {
        case 'DESCRIPTION':
            item.fieldName = locale.get('GIR.ImageDescription');
            break;
        case 'CUST_REF':
            item.fieldName = locale.get('CM.CheckNumber');
            break;
        // no default
        }
        return item;
    },

    /**
     * @param {object} i filter
     * @return {object}
     */
    translateEnteredBy(i) {
        const item = i;
        if (item.primaryValues[0].trim() === 'USERID') {
            item.primaryValues[0] = userInfo.attributes.id || locale.get('filterfield.alert.UserDesc');
        }
        // Needed for SMB - or should there be a proper locale?
        item.fieldName = locale.get('filterfield.EnteredBy');

        return item;
    },
    /**
     * @param {object} i filter
     * @return {object}
     */
    translateScheduled(i) {
        const item = i;
        const scheduledLabels = {
            D: locale.get('ACH.Daily'),
            W: locale.get('ACH.Weekly'),
            M: locale.get('ACH.Monthly'),
            Y: locale.get('ACH.Yearly'),
            'ACH.DAILY': locale.get('ACH.Daily'),
            'ACH.WEEKLY': locale.get('ACH.Weekly'),
            'ACH.MONTHLY': locale.get('ACH.Monthly'),
            'ACH.YEARLY': locale.get('ACH.Yearly'),
        };
        item.primaryValues = util.map(item.primaryValues, value => scheduledLabels[value] || value);
        return item;
    },
    /**
     * @param {object} i filter
     * @return {object}
     */
    translateEntryMethod(i) {
        const item = i;
        item.fieldName = locale.get('CM.EntryMethod');
        item.primaryValues = util.chain(item.primaryValues)
            .map((value) => {
                if (value === '3') {
                    return locale.get('CM.Imported');
                }
                if (value === '4') {
                    return null;
                }
                if (value?.toLowerCase().includes('tmpl.entrymethod')) {
                    return item.labelValue;
                }
                return value;
            })
            .compact()
            .value();
        // Check if primaryValues type is a string and safely update the item type to enum
        const arePrimaryValuesOfTypeString = util.all(item.primaryValues, value => typeof value === 'string');
        if (arePrimaryValuesOfTypeString) {
            item.type = 'enum';
        }
        return item;
    },
    /**
     * @param {object} i - filter
     * @param {array} refCollection - status codes used for reference
     * @param {string} functionCode
     * @returns {object}
     */
    translateStatus(i, refCollection, functionCode) {
        const item = i;
        const disabledCodes = ['MF', 'RR'];
        // TODO: Discover why these outliers aren't included in the service data
        const outliers = [
            {
                code: 'CP',
                localeKey: 'RTGS.ApprovalWindowPassed',
            },
            {
                code: 'PW',
                localeKey: 'Password Violation',
            },
            {
                code: 'QR',
                localeKey: 'gridAction.popup.success.rejected', // QR = 'Request Rejected'
            },
            // HACK Bad data from the backend as STATUS should never be null
            {
                code: 'ISNULL',
                localeKey: 'listviews.needsdecision',
            },
            {
                code: 'AP',
                localeKey: 'AP',
            },
            {
                code: 'DL',
                localeKey: 'DL',
            },
            {
                code: 'EP',
                localeKey: 'PAY.Status_Description.Extract in Process',
            },
            {
                code: 'PB',
                localeKey: 'Pending Bank Approval',
            },
            {
                code: 'TP',
                localeKey: 'Bank Pending',
            },
            {
                code: 'EN',
                localeKey: 'EN',
            },
            {
                code: 'HV',
                localeKey: 'HV',
            },
            {
                code: 'IV',
                localeKey: 'Reversal (Incomplete Approval)',
            },
            {
                code: 'PI',
                localeKey: 'Partial Reversal (Incomplete Approval)',
            },
            {
                code: 'RA',
                localeKey: 'Reversal Awaiting Approval',
            },
            {
                code: 'RI',
                localeKey: 'Partial Reversal Awaiting Approval',
            },
            {
                code: 'IA',
                localeKey: 'IA',
            },
            {
                code: 'RT',
                localeKey: 'RT',
            },
            {
                code: 'TR',
                localeKey: 'TR',
            },
            {
                code: 'NI',
                localeKey: 'NI',
            },
            {
                code: 'RJ',
                localeKey: 'PAY.Rejected',
            },
            {
                code: 'AR',
                localeKey: 'Approver Rejected',
            },
            {
                code: 'NR',
                localeKey: 'Needs Repair',
            },
            {
                code: 'RL',
                localeKey: 'RL',
            },
            {
                code: 'BC',
                localeKey: 'BC',
            },
            {
                code: 'BR',
                localeKey: 'BR',
            },
            {
                code: 'IC',
                localeKey: 'IC',
            }, {
                code: 'ER',
                localeKey: 'ER',
            },
            {
                code: 'ES',
                localeKey: 'ES',
            },
            {
                code: 'PV',
                localeKey: 'PV',
            },
            {
                code: 'RB',
                localeKey: 'RB',
            },
            {
                code: 'RS',
                localeKey: 'RS',
            },
            {
                code: 'RV',
                localeKey: 'RV',
            },
            {
                code: 'RE',
                localeKey: 'RE',
            },
            {
                code: 'LR',
                localeKey: 'Rule Rejected',
            },
            {
                code: 'MD',
                localeKey: 'MD',
            },
            {
                code: 'PA',
                localeKey: 'PS.Partially.Confirmed',
            },
            {
                code: 'PD',
                localeKey: 'PS.Partial.Reversal.Rejected',
            },
            {
                code: 'RC',
                localeKey: 'PS.Reversal.BankConfirmed',
            },
            {
                code: 'RD',
                localeKey: 'PS.screentext.ReversalRejected',
            },
            {
                code: 'TF',
                localeKey: 'PS.Trade.Failed',
            },
            {
                code: 'SP',
                localeKey: 'listviews.enrollment.pending',
            },
        ];
        const ignoreIncompleteStatus = ['REQTMPL', 'TMPL'];
        /**
         * Requests (functionCode = 'REQUEST') shouldn't display
         * Entered, 2nd Approval Needed, Incomplete Approval.
         * Only Entered should be displayed
         */
        if (functionCode === 'REQUEST'
            && util.isEqual(['EN', 'HV', 'IA'], item.primaryValues)) {
            item.primaryValues = ['EN']; // Entered only
        } else if (ignoreIncompleteStatus.includes(functionCode)) {
            // HACK backend should not send a status that is not relevant
            const invalidStatus = ['IA', 'HV'];
            item.primaryValues = item.primaryValues.filter(val => !invalidStatus.includes(val));
        }
        item.primaryValues = item.primaryValues.map((text) => {
            if (util.contains(disabledCodes, text)) {
                return null;
            }
            // Return labels from Status codes available in the service
            const refEnum = util.findWhere(refCollection.toJSON(), {
                text,
            });
            // find any outliers
            const outlier = util.findWhere(outliers, {
                code: text,
            });
            if (outlier) {
                return locale.get(outlier.localeKey);
            }
            return (refEnum) ? refEnum.value : text;
        }).filter(a => !util.isEmpty(a));
        return item;
    },
    /**
     * @param {object} i - filter
     * @return {object}
     * Transaction Date is not always available as a filter on every environment, so it
     * can be displayed on all environ
     */
    translateTransactionDate(i) {
        // TODO:  Could these all be a single utility function - clean up
        const item = i;
        item.fieldName = locale.get('PAY.TranDate');
        return item;
    },
    /**
     * @param {object} i - filter
     * @return {object}
     */
    translateProduct(i) {
        const item = i;
        /**
         * The will deal with an edge case where
         * label should read "Payment Type Real-Time Payments"
         * and not "Product Real-Time Payments"
         */
        if (item.fieldCode === 'PRODUCT' && item.primaryValues && item.primaryValues.includes('RTP')) {
            item.fieldName = locale.get('PAY.PaymentType');
        } else {
            item.fieldName = locale.get('filterfield.product');
        }
        item.primaryValues = this.getLocaleForValues(item.primaryValues);
        return item;
    },
    /**
     * @param {object} i - filter
     * @return {object}
     */
    translateLastTouchedBy(i) {
        const item = i;
        item.fieldName = locale.get('PS.LastChangeBy');
        item.primaryValues = this.getLocaleForValues(item.primaryValues);
        return item;
    },
    /**
     * @param {array} filterValues
     * @return {array}
     */
    getLocaleForValues(filterValues) {
        return util.map(filterValues, value => locale.getDefault(value, value));
    },
    /**
     * @param i filter
     * @param collection - filter references
     * @return {object}
     */
    translateMessageState(i, collection) {
        const item = i;
        item.primaryValues = util.chain(item.primaryValues)
            .map((id) => {
                const model = util.findWhere(collection.models, {
                    id,
                });
                return (model) ? model.get('label') : id;
            })
            .compact()
            .value();
        return item;
    },

    /**
     * @param i filter
     * @return {object}
     */
    translateBAICode(i) {
        const item = i;
        item.type = 'enum';
        const BAI_CODE_LOCALE = {
            475: 'listviews.alltransactions.checkspaid',
        };
        item.overrideFieldName = locale.get('CM.Type');
        item.primaryValues = this.mapPrimaryValues(item.primaryValues, (id) => {
            const value = BAI_CODE_LOCALE[id];
            return value ? locale.get(value) : id;
        });
        return item;
    },

    /**
     * @param {object} i filter
     * @return {object} - modified filter
     */
    translateTransferSet(i) {
        const item = i;
        const transferState = {
            0: locale.get('yes'),
            1: locale.get('yes'),
        };
        item.fieldName = locale.get('ACH.Recurring');
        item.type = 'enum';
        item.primaryValues = this.mapPrimaryValues(item.primaryValues, id => transferState[id]);
        return item;
    },

    /**
     * @param {object} i filter
     * @return {object} - modified filter
     */
    translateStatusDescription(i) {
        const item = i;
        let hidden = false;
        item.primaryValues = this.mapPrimaryValues(item.primaryValues, (id) => {
            // TODO: This is temp fix. Hide tags that have null/NULL values
            if (id && id.toLowerCase().includes('null')) {
                hidden = true;
                return null;
            }
            if (id.includes('PRENOTEFLAG = 1')) {
                return locale.get('listviews.BeneficiariesPrenotesOnly');
            }
            return id;
        });
        return hidden ? null : item;
    },

    /**
     *
     * @param {object} i filter
     * @return {object} modified filter
     * locale wasn't returning correct app resource, fixes that
     */
    translateTypeDescription(i) {
        const item = i;
        item.primaryValues = this.getLocaleForValues(item.primaryValues);
        return item;
    },
    /**
     * @param {object} i filter
     * @return {object} - modified filter
     */
    translateRequestDate(i) {
        const item = i;
        let hidden = false;
        item.primaryValues = this.mapPrimaryValues(item.primaryValues, (id) => {
            // TODO: This is temp fix. Hide tags that have &lt;/&gt; SQL values
            if (id && id.toLowerCase().includes('&lt')) {
                hidden = true;
                return null;
            }
            return id;
        });
        return hidden ? null : item;
    },

    /**
     * @param {object} i filter
     * @return {object} - modified filter
     */
    translateRequestPaymentStatus(i) {
        const item = i;
        item.type = 'enum';
        /**
         * TODO: Add below to app resources
         * XD: Expiration Delivered
         * XN: Expiration Not Delivered
         * OU: Outstanding
         */
        //
        const disabledCodes = ['MF', 'XD', 'XN', 'OU'];
        const outliers = [
            {
                code: 'XS',
                localeKey: 'RTP.action.cancel',
            },
            {
                code: 'NW',
                localeKey: 'PAY.NewTab',
            },
            {
                code: 'BC',
                localeKey: 'PAY.Status_Description.Bank Confirmed',
            },
            {
                code: 'EX',
                localeKey: 'Expired',
            },
            {
                code: 'RJ',
                localeKey: 'PAY.Rejected',
            },
            {
                code: 'RL',
                localeKey: 'PAY.Released',
            },
            {
                code: 'PD',
                localeKey: 'CM.Paid_',
            },
        ];
        item.primaryValues = this.mapPrimaryValues(item.primaryValues, (id) => {
            if (util.contains(disabledCodes, id)) {
                return null;
            }
            // find any outliers
            const outlier = util.findWhere(outliers, {
                code: id,
            });
            if (outlier) {
                return locale.get(outlier.localeKey);
            }
            return id;
        });
        const shouldHideBadge = util.all(item.primaryValues, util.negate(util.identity));
        return shouldHideBadge ? null : item;
    },
    /**
     * @param {object} i filter
     * @return {object} - modified filter
     */
    translateBAIGroupCode(i) {
        const item = i;
        item.fieldName = locale.get('PAY.TransactionType');
        return item;
    },
    /**
     * @param {object} i filter
     * @return {object}
     */
    translateType(i) {
        // item.primaryValues might include 'stop'/'cancel'
        const item = i;
        // Check if primaryValues type is a string and safely update the item type to enum
        const arePrimaryValuesOfTypeString = util.all(item.primaryValues, value => typeof value === 'string');
        // If primary value is CANCEL or STOP, convert to locale form
        item.primaryValues = item.primaryValues.map((primaryItem) => {
            if (primaryItem === 'CANCEL') {
                return locale.get('CM.Cancel');
            }
            if (primaryItem === 'STOP') {
                return locale.get('CM.Stop');
            }
            return primaryItem;
        });
        if (arePrimaryValuesOfTypeString) {
            item.type = 'enum';
        }
        return item;
    },
    /**
     * @param {object} i filter
     * @return {object}
     */
    translateLastActionTime(i) {
        const item = i;
        item.primaryValues = this.mapPrimaryValues(item.primaryValues, (id) => {
            // This will take care of LAST_ACTION_TIME with value BC. No translation needed
            if (id === 'BC') {
                return null;
            }
            if (id === 'L1H') {
                return locale.get('L1H');
            }
            return id;
        });
        const shouldHideBadge = util.all(item.primaryValues, util.negate(util.identity));
        return shouldHideBadge ? null : item;
    },
    /**
     * @param {object} i filter
     * @param {string} functionCode
     * @return {object}
     */
    translateCurrencyCode(i, functionCode) {
        const item = i;
        item.fieldName = locale.get('bab.currency');
        item.primaryValues = this.mapPrimaryValues(item.primaryValues, (id) => {
            // Handles special case in "Recurring Payment Exceptions" (LISTRPT)
            if (functionCode === 'LISTRPT' && id === 'X') {
                return null;
            }
            return id;
        });
        const shouldHideBadge = util.all(item.primaryValues, util.negate(util.identity));
        return shouldHideBadge ? null : item;
    },
    /**
     * @param {object} i filter
     * @return {object}
     */
    translateAccountFilter(i) {
        const item = i;
        item.fieldName = locale.get('reports.account.number');
        item.primaryValues = item.primaryValues.map((v) => {
            const [, ...value] = v.split('-');
            return maskValue(value.join('-'));
        });
        return item;
    },
    /**
     * @param {object} i filter
     * @return {object}
     */
    translateTokenExpDate(i) {
        const item = i;
        if (item.equality === 'BETWEEN') {
            return item;
        }
        const regex = /F(\d{2,})D$/;
        item.primaryValues = item.primaryValues && item.primaryValues.map((it) => {
            const [, days] = it.match(regex) || [];
            if (days) {
                return `${days} ${locale.get('common.days')}`;
            }
            return it;
        });
        return item;
    },
    /**
     * @param {object} item filter
     * @return {object}
     */
    translateUserGroup(item) {
        return {
            ...item,
            primaryValues: item.primaryValues && item.primaryValues.map(it => (it === 'ISNULL' ? `${locale.get('setup.is')} ${locale.get('common.empty')}` : it)),
        };
    },

    /**
     * @param {object} item filter
     * @return {object}
     */
    translateYesNo(item) {
        return {
            ...item,
            primaryValues: [item.primaryValues[0] === '1' ? locale.get('common.yes') : locale.get('common.no')],
        };
    },

    /**
     * @param {string} fieldValue
     */
    translateContentType(fieldValue) {
        const filterItem = fieldValue;
        filterItem.primaryValues = filterItem.primaryValues.map((val) => {
            if (val === 'BD') {
                return locale.get('alert.bankdefined');
            }
            if (val === 'SD') {
                return locale.get('alert.systemdefined');
            }
            return val;
        });
        return filterItem;
    },

    /**
     * @param {object} i filter
     * @return {object}
     */
    translatePositivePayType(i) {
        const item = i;
        item.fieldName = locale.get('risk.pospaytype');
        const key = item.primaryValues[0] === 'NEXTDAY' ? 'risk.pospaytype.nextday' : 'risk.pospaytype.standard';
        item.primaryValues[0] = locale.get(key);

        return item;
    },

    translateDebitCredit(i) {
        const item = i;
        if (item.primaryValues[0].toUpperCase?.() === 'CR') {
            item.primaryValues[0] = locale.get('ACH.Credit');
        } else {
            item.primaryValues[0] = locale.get('ACH.Debit');
        }
        return item;
    },
};
