import util from '@dbiqe/glu-core/src/util';

const days = ['WEEKLYDAYMON', 'WEEKLYDAYTUE', 'WEEKLYDAYWED', 'WEEKLYDAYTHURS',
    'WEEKLYDAYFRI', 'WEEKLYDAYSAT', 'WEEKLYDAYSUN',
];

export default {

    /*
     * keyName and valueName are options
     * will default to 'name'/'value'
     */
    pairsToHash(values, keyName, valueName, unescape) {
        const args = [values, keyName, valueName, unescape].filter(v => v !== undefined);
        let keyNameParam = keyName;
        let valueNameParam = valueName;
        const result = {};
        let unescapeParam = false;
        if (args.length < 3) {
            keyNameParam = 'name';
            valueNameParam = 'value';
        }
        if (args.length === 4) {
            unescapeParam = unescape;
        }
        util.each(values, (pair) => {
            if (!Object.prototype.hasOwnProperty.call(pair, keyNameParam)) {
                throw new Error(`Pair doesn't have a property with a name ${keyNameParam}`);
            } else if (Object.prototype.hasOwnProperty.call(result, pair[keyNameParam])) {
                throw new TypeError(`The key "${pair[keyNameParam]}" appears more than once.`);
            }
            result[pair[keyNameParam]] = (unescapeParam)
                ? (pair[valueNameParam]).replace(/&amp;/g, '&').replace(/&apos;/g, '\'').replace(/&quot;/g, '"')
                : pair[valueNameParam];
        });
        return result;
    },

    // unescape value in the hash
    pairsToHashUnescape(values, keyName, valueName) {
        return this.pairsToHash(values, keyName, valueName, true);
    },

    hashToPairs(hash, keyName, valueName) {
        const args = [hash, keyName, valueName].filter(v => v !== undefined);
        const pairs = [];
        let keyNameParam = keyName;
        let valueNameParam = valueName;
        if (args.length < 3) {
            keyNameParam = 'name';
            valueNameParam = 'value';
        }
        if (hash == null) {
            return pairs;
        }
        Object.keys(hash).forEach((key) => {
            const obj = {};
            obj[keyNameParam] = key;
            obj[valueNameParam] = hash[key];
            pairs.push(obj);
        });
        return pairs;
    },

    /**
     * @method convertScheduleModelToNVP
     * @param {NestedModel} scheduleModel
     * converts a glu/scheduler format model to name value pairs array
     */

    convertScheduleModelToNVP(scheduleModel) {
        let pairs = [];

        if (scheduleModel && scheduleModel.get('type')) {
            const schedModel = scheduleModel;

            let schedAttributes = {
                STARTINGEFFECTIVEDATE: schedModel.get('starts'),
            };

            if (schedModel.get('ends') === false) {
                schedAttributes.ENDMODE = 'noend';
                schedAttributes.ENDCYCLES = '';
                schedAttributes.ENDINGEFFECTIVEDATE = '';
            } else if (Number.isInteger(Number(schedModel.get('ends')))) {
                schedAttributes.ENDMODE = 'numoccur';
                schedAttributes.ENDCYCLES = schedModel.get('ends');
                schedAttributes.ENDINGEFFECTIVEDATE = '';
            } else {
                schedAttributes.ENDMODE = 'effdate';
                schedAttributes.ENDCYCLES = '';
                schedAttributes.ENDINGEFFECTIVEDATE = schedModel.get('ends');
            }

            if (schedModel.get('type') === 'MONTHLY') {
                schedAttributes.PATTERNMODE = 'M';

                if (schedModel.get('recurrence').intervalType === 'DAY') {
                    schedAttributes.MONTHLYMODE = 'specific';
                    schedAttributes.MONTHLYMONTH1 = schedModel.get('recurrence').nMonths;
                    [schedAttributes.MONTHLYDAY1] = schedModel.get('recurrence').onN;
                } else {
                    schedAttributes.MONTHLYMONTHNUMBER = schedModel.get('recurrence').nMonths;
                    schedAttributes.MONTHLYMODE = 'interval';
                    schedAttributes.MONTHLYDAYTYPE = schedModel.get('recurrence').intervalType.toLowerCase();
                    schedAttributes.MONTHLYDAYNUMBER = schedModel.get('recurrence').onN[0].toLowerCase();
                }
            } else if (schedModel.get('type') === 'WEEKLY') {
                schedAttributes.PATTERNMODE = 'W';
                schedAttributes.WEEKLYWEEKS = schedModel.get('recurrence').nWeeks;

                schedAttributes = util.extend(schedAttributes, {
                    WEEKLYDAYMON: '0',
                    WEEKLYDAYTUE: '0',
                    WEEKLYDAYWED: '0',
                    WEEKLYDAYTHURS: '0',
                    WEEKLYDAYFRI: '0',
                    WEEKLYDAYSAT: '0',
                    WEEKLYDAYSUN: '0',
                });

                util.each(schedModel.get('recurrence').onN, (dayIndex) => {
                    schedAttributes[days[dayIndex - 1]] = '1';
                });
            }

            if (!util.isEmpty(schedModel.get('dueDateInputMode')) && !util.isEmpty(schedModel.get('expiresDateInputMode'))) {
                const rfpScheduleData = this.retrieveRFPScheduledData(schedModel);
                if (schedModel.get('dueDateInputMode') === 'DUE_ON') {
                    schedAttributes.PAYMENT_DUE_DAYS = rfpScheduleData.dueOnTheDay;
                    schedAttributes.DUE_DATE_INPUT_MODE = 'DUE_ON';
                    schedAttributes.DUE_DATE_INPUT_TIME = rfpScheduleData.dueOnDateTime;
                } else if (schedModel.get('dueDateInputMode') === 'DUE_WITHIN') {
                    schedAttributes.PAYMENT_DUE_DAYS = rfpScheduleData.dueWithin;
                    schedAttributes.DUE_DATE_INPUT_MODE = 'DUE_WITHIN';
                    schedAttributes.DUE_DATE_INPUT_TIME = rfpScheduleData.dueWithinDateTime;
                }
                schedAttributes.EXPIRES_ON_DUE_DATE = rfpScheduleData.expiresOnDueDate;
                if (schedModel.get('expiresDateInputMode') === 'EXPIRES_ON') {
                    schedAttributes.REQUEST_EXPIRES_DAYS = rfpScheduleData.expiresOnTheDay;
                    schedAttributes.EXPIRATION_DATE_INPUT_MODE = 'EXPIRES_ON';
                    schedAttributes.EXPIRES_DATE_INPUT_TIME = rfpScheduleData.expiresOnDateTime;
                } else if (schedModel.get('expiresDateInputMode') === 'EXPIRES_WITHIN') {
                    schedAttributes.REQUEST_EXPIRES_DAYS = rfpScheduleData.expiresWithin;
                    schedAttributes.EXPIRATION_DATE_INPUT_MODE = 'EXPIRES_WITHIN';
                    schedAttributes.EXPIRES_DATE_INPUT_TIME = rfpScheduleData.expiresWithinDateTime;
                }
            }
            const resetPattern = schedModel.get('resetPattern');
            if (resetPattern) {
                schedAttributes.RESETPATTERN = resetPattern;
            }

            pairs = pairs.concat(this.hashToPairs(schedAttributes));
        }

        return pairs;
    },

    retrieveRFPScheduledData(schedModel) {
        const rfpSchedAttributes = {
            dueOnTheDay: '',
            dueWithin: '',
            dueOnDateTime: '',
            dueWithinDateTime: '',
            expiresOnTheDay: '',
            expiresWithin: '',
            expiresOnDateTime: '',
            expiresWithinDateTime: '',
            expiresOnDueDate: '0',
        };
        if (schedModel.get('dueOnTheDay')) {
            if (util.isArray(schedModel.get('dueOnTheDay'))) {
                [rfpSchedAttributes.dueOnTheDay] = schedModel.get('dueOnTheDay');
            } else {
                rfpSchedAttributes.dueOnTheDay = schedModel.get('dueOnTheDay');
            }
        }
        if (schedModel.get('dueWithin')) {
            rfpSchedAttributes.dueWithin = schedModel.get('dueWithin');
        }
        if (schedModel.get('dueOnDateTime')) {
            rfpSchedAttributes.dueOnDateTime = schedModel.get('dueOnDateTime');
        }
        if (schedModel.get('dueWithinDateTime')) {
            rfpSchedAttributes.dueWithinDateTime = schedModel.get('dueWithinDateTime');
        }
        if (schedModel.get('expiresOnDueDate')) {
            rfpSchedAttributes.expiresOnDueDate = '1';
        }

        if (schedModel.get('expiresOnTheDay')) {
            if (util.isArray(schedModel.get('expiresOnTheDay'))) {
                [rfpSchedAttributes.expiresOnTheDay] = schedModel.get('expiresOnTheDay');
            } else {
                rfpSchedAttributes.expiresOnTheDay = schedModel.get('expiresOnTheDay');
            }
        }
        if (schedModel.get('expiresWithin')) {
            rfpSchedAttributes.expiresWithin = schedModel.get('expiresWithin');
        }
        if (schedModel.get('expiresOnDateTime')) {
            rfpSchedAttributes.expiresOnDateTime = schedModel.get('expiresOnDateTime');
        }
        if (schedModel.get('expiresWithinDateTime')) {
            rfpSchedAttributes.expiresWithinDateTime = schedModel.get('expiresWithinDateTime');
        }
        return rfpSchedAttributes;
    },

};
