export default {
    SHOWCASE_MENU_ITEM: 'SHWCAS',
    URL_ADD_ACTION: '/add',
    URL_ADDCHILDREN_ACTION: '/saveChildren',
    URL_ADDGRANDCHILDREN_ACTION: '/saveGrandChildren',
    URL_APPROVE_ACTION: '/approve',
    URL_AUTHORIZE_ACTION: '/authrize',
    URL_BULK_UPDATE: '/bulkChildrenUpdate',
    URL_DELETE_ACTION: '/delete',
    URL_DELETECHILDREN_ACTION: '/deleteChildren',
    URL_DELETEGRANDCHILDREN_ACTION: '/deleteGrandChildren',
    URL_DETAILAUDITLIST: 'audit/detailAuditListView',
    URL_DETAILROLESAUDITLIST: 'audit/detailRolesAuditListView',
    URL_DISABLE_ACTION: '/disable',
    URL_DO_FIELD_VALIDATION: '/inquiry/processServiceAction',
    URL_GET_NEXT_EARLIEST_AVAILABLE_VALUE_DATE: '/payment/getNextEarliestAvailableValueDate',
    URL_GETACTIONRESTRICTIONS_ACTION: '/getActionRestrictions',
    URL_GETCHILDREN_ACTION: '/details/getChildren',
    URL_GET_BATCH_SEQUENCE: '/batchPayment/getBatchSequence',
    URL_GETCOMPANYENTITLMENTS_ACTION: '/getCompanyEntitlements',
    URL_GETDATESLIST: '/date/list',
    URL_GETENTITLEDACTIONS_ACTION: '/getEntitledActions',
    URL_GETENTITLEMENTSFORCONTEXT_ACTION: '/getEntitledActionsForContext',
    URL_GETEXCHANGERATE: 'rate/validatecontract',
    URL_GETGRIDCOMPONENTRESULTS_ACTION: '/getGridData',
    URL_GETHELPPAGE: '/help/getHelpPage',
    URL_GETINDICATIVERATE_ACTION: 'rate/getIndicativeRate',
    URL_GETINTERESTRATE_ACTION: 'loan/getInterestRate',
    URL_GETLISTVIEW_ACTION: '/getListView',
    URL_GETLOOKUPRESULTS_ACTION: '/tableMaintenance/getLookupResults',
    URL_GETMODELINFO_ACTION: '/getModelInfo',
    URL_GETQUERYRESULTS_ACTION: '/tableMaintenance/getQueryResults',
    URL_GETRATE_ACTION: 'rate/getrate',
    URL_GETTOTALS: '/batch/totals/getBatchTotals',
    URL_HOLDALL_ACTION: '/holdall',
    URL_MULTIUPDATE_ACTION: '/multiUpdate',
    URL_NEED_INFO_ACTION: '/needinfo',
    URL_PAY_ACTION: '/pay',
    URL_PAYMENTISSUED_ACTION: '/paymentIssued',
    URL_POLL_PAY_STATUS: '/pollStatus',
    URL_PROCESS_SERVICE_ACTION: '/inquiry/processServiceAction',
    URL_QUICKTRANSFER_SAVE: '/payment/transfer/multi',
    URL_READ_ACTION: '/read',
    URL_READCHILDREN_ACTION: '/readChildren',
    URL_REJECT_ACTION: '/reject',
    URL_REJECTFM_ACTION: '/rejectfm',
    URL_RELOAD_ACTION: '/refreshModelInfo',
    URL_RELOAD_BATCH_ACTION: '/refreshBatchModelInfo',
    URL_REPAIR_ACTION: '/repair',
    URL_RESETCHILDREN_ACTION: '/details/resetChildren',
    URL_RESTORE_ACTION: '/restore',
    URL_CANCLREQ_ACTION: '/canclreq',
    URL_RETURN_REASON_BMPPDEC: 'cm/listView/positivePayDecision/getQueryResults',
    URL_RETURN_REASON_CMPPDEC: 'cm/listView/reversePositivePayDecision/getQueryResults',
    URL_REVERSE_ACTION: '/reverse',
    URL_RTP_MULTISAVE: 'payment/crtran/multi',
    URL_RTP_PAYMENT_ACKNOWLEDGEMENT: 'realTimeReceivedPayment/conversation/acknowledgement',
    URL_RTP_RECEIVED_CONVERSATION: 'realTimeReceivedPayment/conversation',
    URL_RTP_RECEIVED_MESSAGE_ALLOTMENT_CHECK: 'realTimeReceivedPayment/conversation/messageAllotmentCheck',
    URL_RTP_REQUEST_FOR_INFO: 'realTimeReceivedPayment/conversation/requestForInfo',
    URL_RTP_RESPONSE_TO_REQUEST_FOR_INFO: 'realTimeSubmittedPayment/conversation/response',
    URL_RTP_SUBMITTED_CONVERSATION: 'realTimeSubmittedPayment/conversation',
    URL_RTP_SUBMITTED_MESSAGE_ALLOTMENT_CHECK: 'realTimeSubmittedPayment/conversation/messageAllotmentCheck',
    URL_RTP_REQUEST_SUBMIT_MULTI_ACTION: '/requestForPayment/outgoingPaymentRequests/listView',
    URL_RFP_RESTORE_TEMPLATE: 'TEMPLATES/requestForPaymentRestoreTemplate',
    URL_STOP_MULTISAVE: 'cm/placeStop/multi',
    URL_TRADE_ACTION: 'rate/trade',
    URL_UNAPPROVE_ACTION: '/unapprove',
    URL_UNVALIDATE_ACTION: '/unvalidate',
    URL_UPDATE_ACTION: '/update',
    URL_UPDTAPPRV_ACTION: '/UPDTAPPRV',
    URL_EXTEND_ACTION: '/multi',
    URL_VALIDATE_ACTION: '/validate',
    URL_GET_DATA: '/inquiry/getData',
    URL_RTP_SUBMITTED: '/requestForPayment/outgoingPaymentRequests',
    URL_PAYANDADJUST: '/multi/multiUpdate/PADJUST',

    ROUTE_RTP_VIEW_REQUEST_FOR_PAYMENT_IN: 'PAYMENTS/viewRequestForPayment',
    ROUTE_RTP_MODIFY_REQUEST_FOR_PAYMENT_OUT: 'PAYMENTS/modifyRequestForPaymentOut',
    ROUTE_RTP_VIEW_REQUEST_FOR_PAYMENT_OUT: 'PAYMENTS/viewRequestForPaymentOut',
    ROUTE_RTP_MODIFY_REQUEST_FOR_PAYMENT_TEMPLATE_OUT: 'TEMPLATES/modifyRequestForPaymentOut',
    ROUTE_RTP_VIEW_REQUEST_FOR_PAYMENT_TEMPLATE_OUT: 'TEMPLATES/viewRequestForPaymentOut',

    // context
    DETAILAUDIT_CONTEXT: 'DETAIL_AUDIT_INFO',

    // Extend Stop Options
    ACTION_EXTEND: 'EXTEND',

    DETAILEXTRACT_AUDIT: 'DEXAUDIT',
    DETAILEXTRACT_RFPAUDIT: 'RFPAUDIT',
    ACTION_INSERT: 'INSERT',
    ACTION_SCHEDULE: 'SPAYMT',
    ACTION_SELECT: 'SELECT',
    ACTION_APPROVE: 'APPROVE',
    ACTION_UNAPPROVE: 'UNAPPROVE',
    ACTION_REJECT: 'REJECT',
    ACTION_GETRATE: 'GETRATE',
    ACTION_DELETE: 'DELETE',
    ACTION_DELETEPAY: 'DELETEPAY',
    ACTION_UPDATE: 'UPDATE',
    ACTION_MODIFY: 'MODIFY',
    ACTION_IMPORT: 'IMPORT',
    ACTION_RETURN: 'RETURN',
    ACTION_MULTIUPDATE: 'MULTIUPDATE',
    ACTION_PAY: 'PAY',
    ACTION_PAYISSUE: 'PAYISSUE',
    ACTION_PAYRULE: 'PAYRULE',
    ACTION_UPDTAPPRV: 'UPDTAPPRV',
    ACTION_VIEWCHECKS: 'VIEWCHECKS',
    ACTION_VALIDATE: 'VALIDATE',
    ACTION_INVALIDATE: 'INVALIDATE',
    ACTION_SIMULATE: 'SIMULATE',
    ACTION_COPYPAYFROMTMPL: 'COPYPAYFROMTMPL',
    ACTION_TEMPLATEGROUP: 'TEMPLATEGROUP',
    ACTION_ASSIGN: 'ASSIGN',
    ACTION_RESTORE: 'RESTORE',
    ENTRYMETHOD: 'ENTRYMETHOD',
    CANCEL_FROM_WARNING_INDICATOR: '_cancelFromWarning',
    SAVE_INCOMPLETE_INDICATOR: '_saveIncomplete',
    SAVE_WITHWARNING_INDICATOR: '_saveWithWarning',
    DUPLICATE_ACCEPTED_INDICATOR: 'duplicateAccepted',
    CONTACT_CHANGES_ACCEPTED_INDICATOR: 'contactChangesAccepted',
    BENE_UPDATED_ACCEPTED_INDICATOR: 'beneUpdatedChangesAccepted',
    PREMIUM_ACH_VENDOR_IGNORED: 'OVERRIDE.PREMIUMACHVENDOR',
    ROW_GROUPS_MAX_COLUMNS: 12,
    COLUMN_TYPE_HIDDEN: 6,
    COLUMN_TYPE_STRING: 0,
    COLUMN_TYPE_MESSAGE: 4,
    COLUMN_TYPE_DATE: 2,
    COLUMN_TYPE_AMOUNT: 1,
    COLUMN_TYPE_EDIT_AMOUNT_SCS: 19,
    COLUMN_TYPE_AMOUNT_SCS: 27,
    COLUMN_TYPE_NUMERIC: 8,
    COLUMN_TYPE_ACTION_LIST: 32,
    COLUMN_TYPE_ENTITLEMENT_ACTION_LIST: 38,
    COLUMN_TYPE_ACCESSIBILITY_ACTION_LIST: 39,
    COLUMN_TYPE_HIDDEN_DATE: 48,
    COLUMN_TYPE_CHECKBOX: 5,
    COLUMN_TYPE_COMBO: 16,
    COLUMN_TYPE_BOOLEAN: 17,
    COLUMN_TYPE_RADIO: 12,
    COLUMN_TYPE_GMT_DATETIME: 49,
    COLUMN_TYPE_PAYMENT_DETAILS: 52,
    COLUMN_TYPE_MASKED_ACCOUNT: 55,
    COLUMN_TYPE_GMT_TIMESTAMP: 29,
    COLUMN_TYPE_IMAGE_INDICATOR: 102,
    COLUMN_TYPE_RATETRADERESULT: 103,
    COLUMN_TYPE_IMAGE_NOTE_INDICATOR: 104,
    COLUMN_TYPE_LOCKBOX_CHECK_DATA: 105,
    COLUMN_TYPE_LOCKBOX_AMOUNT: 106,
    COLUMN_TYPE_LOCKBOX_DATE: 107,
    COLUMN_TYPE_LINK: 260,
    COLUMN_TYPE_SYSTEMDATE: 50,
    COLUMN_TYPE_ACTION_TABLE_ITEM: 51,
    COLUMN_TYPE_DUMMY: 60,
    COLUMN_TYPE_HIDDEN_IF_NOSECONDARY: 110,
    COLUMN_TYPE_HIDDEN_IF_NOSECONDARY2: 111,
    COLUMN_TYPE_IMAGE_CONVERSATION: 112,
    COLUMN_TYPE_ENUM: 'ENUM',
    COLUMN_TYPE_TYPEAHEAD: 'TYPEAHEAD',
    COLUMN_TYPE_FROMSERVER_TYPEAHEAD: 21,
    COLUMN_TYPE_FROMSERVER_DYNAMIC_DATE: 4,
    COLUMN_TYPE_DECIMAL: 36,
    COLUMN_ALIGN_LEFT: 0,
    COLUMN_ALIGN_RIGHT: 1,
    COLUMN_ALIGN_CENTER: 2,
    COLUMN_MAX_CHARACTERS: 80,
    GRID_ROWS_PER_PAGE: 50,
    GRID_CURRENT_PAGE: 1,
    DATEPICKER_MAXDATE: 45,
    DATEPICKER_MAXCUTOFFTIME: '-5:00',
    FIELDNAME_INDEX: 1,
    MASKED_INPUT_FILTER_TYPE: 25,

    // number of rows per page in lookup grid
    LOOKUP_GRID_ROWS_PER_PAGE: 10,

    /* for field classes */
    SMALLTEXT: 10,

    MEDIUMTEXT: 50,
    LARGETEXT: 100,
    TEXTAREAROWS: 1,
    LF_ASCII: '10',
    CR_ASCII: '13',
    TAB_ASCII: '9',
    SPACE_ASCII: '32',
    COMMA_ASCII: '44',
    SEMICOLON_ASCII: '59',
    DOUBLE_QUOTE_ASCII: '34',
    CR_LF: '1310',
    NEEDSRATEVIEWID: '20',
    NEEDSRATEID: 'Needs Rate',
    TRANSFERNEEDSRATEVIEWID: '19020',
    FIELD_UI_TYPE_DATEFILTER: 'DATEFILTER',
    FIELD_UI_TYPE_TIMEFIELD: 'TIMEFIELD',
    FILTER_TYPE_GMTDATE: 12,
    FILTER_TYPE_SINGLE_DATE_FILTER: 19,
    COMBO_MIN_CHARS: 3,
    COMBO_MIN_SIZE: 10,
    COMBO_MAX_SIZE: 500,
    COMBO_DEBOUNCE: 350,

    // Entry Methods
    ENTRY_METHOD_FREEFORM: 0,

    ENTRY_METHOD_TEMPLATE: 1,
    ENTRY_METHOD_REPETITIVE: 2,
    ENTRY_METHOD_IMPORT: 3,

    // Most comparisons are string.
    ENTRY_METHOD: {
        FREEFORM: '0',
        TEMPLATE: '1',
        REPETITIVE: '2',
        IMPORT: '3',
    },
    // for typeahead lookups
    MAX_SERVER_ROWSPERPAGE: 250,

    PAYMENT_TYPES_USING_BENEFICIARY_WIDGET: [
        'BDACHDC',
        'BDACHCP',
        'BDACHCC',
        'BDACHCCD',
        'BDACHCD',
        'BDACHCCP',
        'BDACHCEC',
        'BDACHCRC',
        'BDACHCVP',
        'BDACHIIC',
        'BDACHP',
        'BDACHTIC',
        'BDACHPRM',
    ],

    TIMEOUTSESSION: 120,

    /*
     * FIXME: When PAYMENT_TYPES_USING_CLEARBENE_BEHAVIOR changes,
     *  we should revisit/remove NH-47184.
     */
    PAYMENT_TYPES_USING_CLEARBENE_BEHAVIOR: [
        'BDACHTP',
        'BDACHIAT',
        'BDACHCTX',
        'BDACHCSP',
        'BDACHP',
        'BDACHPRM',
    ],

    PAYMENT_TYPES_NO_PRINT_BUTTON_DETAIL_PAGE: [],

    QUICK_TRANSFER_REQUEST_KEYS: {
        from: [
            'ACCOUNTFILTER',
            'DEBIT_ACCOUNT_NUMBER',
            'DEBIT_CURRENCY',
            'ACCOUNT_TYPE',
            'DEBITACCOUNTEXTERNALFLAG',
            'DEBIT_ACCOUNT_BANK_NAME',
            'DEBIT_ACCOUNT_TITLE',
            'DEBIT_BANKNAME',
            'DEBIT_BANK_CODE',
            'DEBIT_COUNTRY',
            'ORIGINATOR_ADDRESS_1',
            'ORIGINATOR_ADDRESS_2',
            'ORIGINATOR_CITY',
            'ORIGINATOR_COUNTRY',
            'ORIGINATOR_NAME',
        ],

        to: [
            'BENE_ACCOUNTENTITLEMENT',
            'BENE_ACCOUNT',
            'BENE_ACCOUNT_TYPE',
            'BENE_BANK_CODE',
            'BENE_BANK_COUNTRY',
            'BENE_BANK_NAME',
            'BENE_NAME',
            'CREDIT_CURRENCY',
        ],

        defaults: {
            FUNCTION: 'INST',
            PAYMENTMETHOD: '',
            PRODUCT: 'RTGS',
            TYPE: 'TRANSFER',
            SAVEASTEMPLATE: '0',
            FUNCTIONCODE: 'INST',
        },

        requiredDataProps: [
            'ACCOUNTFILTER',
            'BENE_ACCOUNTENTITLEMENT',
            'CREDIT_AMOUNT',
            'TRAN_DATE',
        ],

        fullRequestPropsSet: [
            'ACCOUNTFILTER',
            'BENE_ACCOUNTENTITLEMENT',
            'CREDIT_AMOUNT',
            'CREDIT_CURRENCY',
            'DEBIT_ACCOUNT_NUMBER',
            'DEBIT_CURRENCY',
            'FUNCTION',
            'PAYMENTMETHOD',
            'PRODUCT',
            'TRAN_DATE',
            'TYPE',
            'USERGROUP',
            'VALUE_DATE',
            'ACCOUNT_TYPE',
            'BENE_ACCOUNT',
            'BENE_ACCOUNT_TYPE',
            'BENE_BANK_CODE',
            'BENE_BANK_COUNTRY',
            'BENE_BANK_NAME',
            'BENE_NAME',
            'DEBITACCOUNTEXTERNALFLAG',
            'DEBIT_ACCOUNT_BANK_NAME',
            'DEBIT_ACCOUNT_TITLE',
            'DEBIT_BANKNAME',
            'DEBIT_BANK_CODE',
            'DEBIT_COUNTRY',
            'ORIGINATOR_ADDRESS_1',
            'ORIGINATOR_ADDRESS_2',
            'ORIGINATOR_CITY',
            'ORIGINATOR_COUNTRY',
            'ORIGINATOR_NAME',
            'SAVEASTEMPLATE',
            'FUNCTIONCODE',
        ],
    },

    // Payment Beneficiary Bulk Update
    BULK_UPDATE: {
        CODES: {
            NEGATIVE_AMOUNT: 19003,
        },
    },

    // Account Masking Values
    ACCOUNT_MASKING: {
        PROPERTIES: {
            ACCOUNT_NUMBER_MAXLEN: 35,
        },
    },
    // Payload for dynamic Future Date List
    DYNAMIC_DATE_OPTION_PAYLOAD: {
        actionMode: 'INSERT',
        subTypeCode: '*',
        entryClass: '',
        allowDuplicates: false,
    },

    /*
     * This is a standard save error for payments and templates.
     * This code means simply, "Something went wrong".
     * It is up to the individual error messages to indicate what went wrong.
     */
    GRID_UPDATE: {
        CODES: {
            STANDARD_SAVE_ERROR: 20003,
        },
    },

    /*
     * Payment Rtgs Iban Lookup Craziness
     * Bank Code Start: Unlisted country codes have a default start position of 4.
     */
    IBAN_BANK_CODE_START: {
        '05': [
            'IT',
            'SM',
        ],
    },
    /*
     * Bank Code End: Unlisted country codes have a default of zero.
     * Countries without a bank code end are undefined in the SWIFT documentation.
     */
    IBAN_BANK_CODE_END: {
        '06': [
            'EE',
            'GE',
            'MD',
            'SA',
            'SI',
            'TN',
        ],
        '07': [
            'AE',
            'AL',
            'BA',
            'BE',
            'CY',
            'FI',
            'GR',
            'HU',
            'IL',
            'KZ',
            'LU',
            'ME',
            'MK',
            'PL',
            'RS',
            'SE',
        ],
        '08': [
            'AD',
            'AZ',
            'BH',
            'BG',
            'CR',
            'CZ',
            'DK',
            'DO',
            'EG',
            'ES',
            'FO',
            'GB',
            'GI',
            'GL',
            'GT',
            'IE',
            'IS',
            'KW',
            'LB',
            'LV',
            'MT',
            'NL',
            'NO',
            'PK',
            'PS',
            'PT',
            'RO',
            'SK',
            'VG',
        ],
        '09': [
            'AT',
            'CH',
            'FR',
            'LI',
            'LT',
            'MC',
            'MR',
            'TR',
        ],
        10: [
            'IT',
            'MU',
            'SM',
            'UA',
        ],
        11: [
            'HR',
        ],
        12: [
            'BR',
            'DE',
        ],
    },
    // Branch Code End: Unlisted country codes have a default of zero. (No branch code)
    IBAN_BRANCH_CODE_END: {
        '08': [
            'BY',
            'EE',
            'JO',
            'QA',
        ],
        '09': [
            'SI',
            'TN',
        ],
        10: [
            'BA',
            'FI',
            'IL',
            'IS',
            'UA',
        ],
        11: [
            'GR',
            'HU',
        ],
        12: [
            'AD',
            'AL',
            'BG',
            'CY',
            'EG',
            'ES',
            'MU',
            'PL',
            'PT',
        ],
        13: [
            'ML',
            'MT',
        ],
        14: [
            'BL',
            'CZ',
            'FR',
            'GB',
            'GF',
            'GG',
            'GP',
            'IE',
            'IM',
            'JE',
            'MC',
            'MF',
            'MQ',
            'MR',
            'NC',
            'PF',
            'PM',
            'RE',
            'TF',
            'WF',
            'YT',
        ],
        15: [
            'IT',
            'SM',
        ],
        16: [],
        17: [
            'BR',
        ],
    },

    /**
     * To Date these have been only for printing. With the addition of
     * export inquiry IDs, adding an object for export specific inquiry ids.
     * DO NOT include export inquiry ids here, add them below.
     */
    // Stops list
    INQUIRY_ID_20577: 20577,

    // Panel maintenance approval list
    INQUIRY_ID_17019: 17019,

    // Admin payment management list SUMMARY
    INQUIRY_ID_20007: 20007,

    // Template management list SUMMARY
    INQUIRY_ID_20008: 20008,

    // Payments list
    INQUIRY_ID_20017: 20017,

    // Templates list
    INQUIRY_ID_20018: 20018,

    // Request for Payment Received list Client
    INQUIRY_ID_20019: 20019,

    // Request for Payment Received list Admin
    INQUIRY_ID_20119: 20119,

    // Transfer list non-admin
    INQUIRY_ID_20027: 20027,

    // Transfer list view export admin
    INQUIRY_ID_20047: 20047,
    // Transfer list view admin
    INQUIRY_ID_20037: 20037,

    // Template management list
    INQUIRY_ID_20082: 20082,

    // TODO if possible associate with specific view, not just product code ADMPAY
    INQUIRY_ID_20083: 20083,

    // SMB Payment list SUMMARY
    INQUIRY_ID_20104: 20104,

    // SMB Template list SUMMARY
    INQUIRY_ID_20597: 20597,

    // SMB Employee Payment list SUMMARY
    INQUIRY_ID_20106: 20106,

    // SMB Employee Template list SUMMARY
    INQUIRY_ID_20599: 20599,

    // Electronic positive pay decision list
    INQUIRY_ID_20520: 20520,
    INQUIRY_ID_20522: 20522,

    // Positive pay decision list
    INQUIRY_ID_20530: 20530,

    // Reverse positive pay decision list
    INQUIRY_ID_20540: 20540,

    // Check issues list SUMMARY
    INQUIRY_ID_20569: 20569,

    // Payment fraud control list SUMMARY
    INQUIRY_ID_20578: 20578,

    // Import results list SUMMARY
    INQUIRY_ID_20579: 20579,

    // Paperless Setting list
    INQUIRY_ID_20701: 20701,

    // Scheduled exports list SUMMARY
    INQUIRY_ID_49068: 49068,

    // Scheduled exports list
    INQUIRY_ID_49070: 49070,

    // RFP Admin submitted list
    INQUIRY_ID_28601: 28601,

    // RFP Admin templates list
    INQUIRY_ID_28602: 28602,

    // Transmission Profile list
    INQUIRY_ID_72209: 72209,

    // Positive pay decision list on Admin
    INQUIRY_ID_29500: 29500,

    // Reverse positive pay decision list on Admin
    INQUIRY_ID_29501: 29501,

    // Printing for alert content management
    INQUIRY_ID_24444: 24444,
    INQUIRY_ID_24445: 24445,

    // ACH Customer Setup Inquiry ID export and print
    INQUIRY_ID_98922: 98922,

    // ACH WareHouse reportId for Batches export and print
    DETAIL_REPORT_ID_FOR_BATCH: 88891,

    // ACH WareHouse reportId for Batches export and print
    DETAIL_REPORT_ID_FOR_ACH_CANCEL_REVERSAL_BATCH: 88900,

    // CheckFree Bill Pay Subscriber Registration list view for print and export
    INQUIRY_ID_CHECKFREE_SUBSCRIBER: 44030,

    // CheckFree Bill Pay Maintenance Accounts list view for print and export
    INQUIRY_ID_CHECKFREE_MAINT_ACCOUNTS: 46000,

    // CheckFree Bill Pay Maintenance Users list view for print and export
    INQUIRY_ID_CHECKFREE_MAINT_USERS: 46005,

    // Admin User Maintenance list view print and export
    INQUIRY_ID_USER_MAINT: 9030,

    // Inquiry IDs specific to exporting
    exportInquiry: {
        // Contact list
        INQUIRY_ID_72206: 72206,
        INQUIRY_ID_17056: 17056,
    },

    // Inquiry IDs for Contact Center Items Affected Grid Lookup
    babItemsAffected: {
        INQUIRYID_PAYMENTS_AFFECTED: 19450,
        INQUIRYID_TEMPLATES_AFFECTED: 19451,
    },

    // viewIds for detail
    DETAIL_AUDIT_EXPORT_VIEW_ID: '16306',
    DETAIL_RFPAUDIT_EXPORT_VIEW_ID: '16307',

    messageType: {
        info: 'info',
        warning: 'warning',
    },

    // for server errorCodes
    PREFUND_WARNING_CODE: 103,
    CONSUMER_DISCLOSURE_ERROR_CODE: 514,
    DUPLICATE_ERROR_CODE: 540,
    REBATEPOTENTIAL_ERROR_CODE: 552,
    ITEMS_EFFECTED_WARNING_CODE: 541,
    BENE_UPDATED_APPROVE_ERROR_CODE: 542,
    BENE_UPDATED_ERROR_CODE: 543,
    BALANCE_CHECK_ERROR_CODE: 551,
    INVALID_ACCOUNT_STATE_ERROR_CODE: 515,
    SERVICE_ERROR_CODE: 20003,

    zeroDecimals: [
        'BIF', 'CLP', 'DJF', 'GNF', 'HUF', 'ISK', 'JPY', 'KMF', 'KRW', 'PYG', 'RWF', 'TWD',
        'UGX', 'UYI', 'VND', 'VUV', 'XAF', 'XOF', 'XPF',
    ],
    threeDecimals: ['BHD', 'IQD', 'JOD', 'KWD', 'LYD', 'OMR', 'TND'],

    /**
     * NH-116255 Width of 132px corresponds to editing a max amount NACHA cell in a Beneficiary
     * Grid. If the max amount is changed, the width should be updated. If the max amount is made
     * dynamic (e.g. config param), the width should be changed as well.
     */
    NACHA_CELL: {
        MAX_AMOUNT: 99999999.99,
        WIDTH: 132,
    },

    // Context object containing values needed to navigate from Pay to Request on Client
    RTP_INST_CRTRAN_CONTEXT: {
        context: {
            actionData: {
                entryMethod: 0,
                functionCode: 'REQUEST',
                productCode: 'RTP',
                subType: '*',
                typeCode: 'REQIN',
            },
            childActionData: null,
            keyList: ['MESSAGE_ID'],
            serviceName: '/requestForPayment/requestReceived',
            isFromDetail: true,
        },
        key: {
            MESSAGE_ID: true,
        },
        storeName: 'requestForPayment_requestReceived-actionModel',
        storeNameSMB: 'requestForPayment_requestReceived-actionModel',
        navigateRoute: 'PAYMENTS/viewRequestForPayment',
        navigateRouteSMB: 'PAYMENTS/viewRequestForPayment',
        keyNameNameInOldModel: 'RFP_MSG_ID',
        returnRoute: 'PAYMENTS/viewPayment',
        returnRouteSMB: 'payments/viewModifypayments/view',
        lookupActions: false,
        isAdminContext: false,
    },
    // Context object containing values needed to navigate from Pay to Request on Admin
    ADMPAY_INST_CRTRAN_CONTEXT: {
        context: {
            actionData: {
                entryMethod: 0,
                functionCode: 'REQUEST',
                productCode: 'ADMPAY',
                subType: '*',
                typeCode: 'REQIN',
            },
            childActionData: null,
            keyList: ['MESSAGE_ID'],
            serviceName: '/requestForPayment/requestReceived',
            isFromDetail: true,
        },
        key: {
            PRODUCTCODE: true,
            FUNCTIONCODE: true,
            TYPECODE: true,
            SUBTYPE: true,
            ENTRYMETHOD: true,
            MESSAGE_ID: true,
            USERGROUP: true,
        },
        storeName: 'requestForPayment_requestReceived-actionModel',
        storeNameSMB: 'requestForPayment_requestReceived-actionModel',
        navigateRoute: 'PAYMENTS/viewRequestForPayment',
        keyNameNameInOldModel: 'RFP_MSG_ID',
        returnRoute: 'REPORTING/viewPaymentsDetails',
        returnRouteSMB: 'REPORTING/viewPaymentsDetails',
        lookupActions: false,
        isAdminContext: true,
    },
    // Context object containing values needed to navigate from Request to Pay on Client
    RFPILIST_REQUEST_REQIN_CONTEXT: {
        context: {
            actionData: {
                entryMethod: 0,
                functionCode: 'INST',
                productCode: 'RTP',
                subType: '*',
                typeCode: 'CRTRAN',
            },
            childActionData: null,
            keyList: ['TNUM'],
            serviceName: '/payment/crtran',
            isFromDetail: true,
        },
        key: {
            TNUM: true,
        },
        storeName: 'payment_listView_corp-actionModel',
        storeNameSMB: 'payment_listView_smb-actionModel',
        navigateRoute: 'PAYMENTS/viewPayment',
        navigateRouteSMB: 'payments/viewPayment',
        keyNameNameInOldModel: 'PAYMENT_TNUM',
        returnRoute: 'PAYMENTS/viewRequestForPayment',
        returnRouteSMB: 'PAYMENTS/viewRequestForPayment',
        lookupActions: true,
        useInquiryID: false,
        inquiryTypeCode: '*',
        inquiryFunctionCode: 'INST',
        inquiryProductCode: 'PAY',
        inquiryAction: 'SELECT',
        returnLinkAfterAction: 'PAYMENTS/managePayments',
        returnLinkAfterActionSMB: 'PAYMENTS/smbManagePayments',
    },
    // Context object containing values needed to navigate from Request to Pay on Admin
    ADMPAY_REQUEST_REQIN_CONTEXT: {
        context: {
            actionData: {
                entryMethod: 0,
                functionCode: 'INST',
                productCode: 'RTP',
                subType: '*',
                typeCode: 'CRTRAN',
            },
            childActionData: null,
            keyList: ['TNUM'],
            serviceName: 'adminPayment/listView/payments',
            isFromDetail: true,
        },
        key: {
            PRODUCTCODE: true,
            FUNCTIONCODE: true,
            TYPECODE: true,
            SUBTYPE: true,
            ENTRYMETHOD: true,
            TNUM: true,
        },
        storeName: 'adminPayment_listView_payments-actionModel',
        storeNameSMB: 'adminPayment_listView_payments-actionModel',
        navigateRoute: 'REPORTING/viewPaymentsDetails',
        navigateRouteSMB: 'REPORTING/viewPaymentsDetails',
        keyNameNameInOldModel: 'PAYMENT_TNUM',
        returnRoute: 'PAYMENTS/viewRequestForPayment',
        returnRouteSMB: 'PAYMENTS/viewRequestForPayment',
        lookupActions: false,
        useInquiryID: false,
        inquiryTypeCode: '*',
        inquiryFunctionCode: 'INST',
        inquiryProductCode: 'RTP',
        inquiryAction: 'SELECT',
        returnLinkAfterAction: 'REPORTING/viewPayments',
        returnLinkAfterActionSMB: 'REPORTING/viewPayments',
    },

    RTP_REQUEST_TEMPLATE_ENTRY_OPTIONS: {
        typeCode: 'REQOUT',
        entryMethod: 1,
        action: 'INSERT',
        productCode: 'RTP',
        subtype: '*',
        functionCode: 'REQUEST',
        context: {
            serviceName: 'payment/rtp',
        },
    },

    RFP_REQUEST_TEMPLATE_ENTRY_OPTIONS: {
        typeCode: 'REQOUT',
        entryMethod: 0,
        action: 'INSERT',
        productCode: 'RTP',
        subtype: '*',
        functionCode: 'REQTMPL',
        context: {
            serviceName: 'payment/rtp',
        },
    },

    BUTTON_SUBMIT: 'BUTTON_SUBMIT',
    BUTTON_CANCEL: 'BUTTON_CANCEL',
    BUTTON_AUTHORIZE: 'BUTTON_AUTHORIZE',

    FILTER_TYPES: {
        number: 0,
        amount: 0,
        enum: 1,
        string: 1,
        text: 1,
        typeahead: 3,
        date: 4,
        gmtdate: 12,
        gmtdatetime: 49,
        masked: 1,
    },

    TRANSLATION_INQUIRY_ID: {
        status: 19203,
        messages: 28702,
    },

    /**
     * list of values to exclude from input Masking
     * these values should be uppercase
     */
    MASKEDEXCLUDEVALUES: [
        'MULTI',
    ],

    REQIN_CONTEXTKEY: 'requestForPayment_requestReceived',

    /**
     * for detail role audit
     */
    CROLE_TM: 'CROLE_TM',
    ADMIN_PRODUCTCODE: '_ADMIN',
    ROLES_FUNCTIONCODE: 'ROLES',
    ROLE_TYPECODE: '_ROLE',
    ROLE_VIEWID_FOR_DETAIL_AUDIT: 883,
    ELECTRONIC_POS_PAY_REALTIME_API: '/risk/listView/ePositivePayDecision/requestRealTimeAchPosPaySuspects',
    MFA_ERROR_CODE: 135,
    SHOW_ID_IN_TITLE: ['cminst.placeStop_title-view', 'cminst.cancelStop_title-view', 'cminst.cancelStop_title-modify', 'cminst.extendStop_title-view'],
    PLACE_STOP_DEFAULT_VALUES: {
        productCode: 'CM',
        functionCode: 'INST',
    },

    REBATE_POTENTIAL_ALERT_TYPES: {
        ADD_EDIT: 'ADD_EDIT',
        ADD_EDIT_BENE: 'ADD_EDIT_BENE',
        APPROVE: 'APPROVE',
        APPROVE_MULTI: 'APPROVE_MULTI',
    },
};
